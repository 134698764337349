import axios from 'axios';


const getToken = () : string | null => {
    const token = localStorage.getItem('token')
    
    return token;
}

const getAxiosInstance = () : any => {

    let axiosInstance = null;

    const token = getToken();
    if (token!=null) {
        axiosInstance = axios.create({
            baseURL: process.env.REACT_APP_URL_BACKEND,
            headers: {
              'Authorization': 'Bearer ' + getToken()
            }
          });
          return axiosInstance;
    }
    return null;
}
export default getAxiosInstance;