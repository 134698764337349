import {useContext, useState,useEffect, useRef, createRef, RefObject} from 'react';
import { locale, addLocale, updateLocaleOption, updateLocaleOptions, localeOption, localeOptions } from 'primereact/api';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { DeviceContext } from '../../providers/DeviceProvider';
import { InputText } from 'primereact/inputtext';
import { Controller, useForm } from 'react-hook-form';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import ITask from '../../../../../commons/types/tasks/ITask';
import { classNames } from 'primereact/utils';
import IUser from '../../../../../commons/types/users/IUser';
import genericAxiosInstance from '../../axios/GenericAxios';
import IGenericData from '../../../../../commons/types/genericData/IGenericData';
import userAxiosInstance from '../../axios/UserAxios';
import taskAxiosInstance from '../../axios/TaskAxios';
import { Checkbox } from 'primereact/checkbox';
interface Props { 
    visible:boolean
    setVisible: (a:boolean) => void
    onChange:() => void;
    data: ITask;
    toast: (obj:any) => void
    fetch: (showWts:boolean,data:ITask) => Promise<void>;
}
interface Country {
    name: string;
    code: string;
}

addLocale('es', {
    firstDayOfWeek: 1,
    dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
    dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
    dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
    monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
    monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
    today: 'Hoy',
    clear: 'Limpiar',
    dateFormat : 'dd/mm/yy'
    //...
});
interface ITaskTmp extends ITask {
    telefono : string;
}
const ModalTask: React.FC<Props> = ({visible,setVisible,data,toast,fetch} : Props)  => {
    const { reset,trigger,control,register, handleSubmit, watch,setValue, formState: { errors } } = useForm<ITaskTmp>();
    const watchAsignacion                       = watch("userAsigned");
    const refAsign                              = useRef<Dropdown>(null);
    const [task,setTask]                            = useState<ITask>();
    const [filterValue, setFilterValue]             = useState<string>();
    const [visibleTel,setVisibleTel]                = useState<boolean>(false);
    const [chkWts,setChkWts]                        = useState<boolean>(true);
    const { isMobile }                              = useContext(DeviceContext);
    const [listContacts, setListContacts]           = useState<IUser[]>([]);
    const [listObras, setListObras]                 = useState<IGenericData[]>([]);
    const refPhone  : RefObject<HTMLInputElement>  = createRef<HTMLInputElement>();
    const [buttonClassesSave, setButtonClassesSave]     = useState(['pi', 'pi-save']);//pi pi-save pi-spin

    const onSubmit = async (idata : ITaskTmp) => {
        if (typeof idata.userAsigned === 'string') {
            const asignTo : IUser = {id:null,username:idata.userAsigned,telefono:idata.telefono} as any
            idata.userAsigned = asignTo;
        }
        try {

            saving();
            const task : ITask = await taskAxiosInstance.createUpdate(idata);
            saved();
            fetch((data.id == null ? chkWts : false),task);
            setVisible(false)
            toast({ severity: 'info',  detail: <div>La tarea <b>{idata.description}</b> fue {idata.id == null ? 'creada' : 'editada'}</div>});


            
        } catch (e : any) {
            toast({ severity: 'error',  detail: e.message });
            saved();
            console.error(e);
        }
        
    };
    
    

    const fetchGenerics = async () => {
        const list = await genericAxiosInstance.fetch(2);
        setListObras(list);
    }

    const fetchContacts = async () : Promise<IUser[]> => {
        const list = await userAxiosInstance.fetch();
        let res = list.map((t) => {
            t.username = (t.rol == 1 ? "*" + t.username : t.username)
            return t;
        })

        setListContacts(res);
        return res;
    }

    useEffect(() => {
        //setDataList(countries)
        //setListCountries(countries);
        
        //const tmp : ITask = {id:3,descripcion:"",fechaAlta:new Date(),fechaVencimiento:new Date(),obra:{ nombre: 'Obra 4', id: 4,estado:1 }, asignacion:{ nombre: 'Persona 1', id: 1,estado:1 },estado:1};
        //setTask(tmp)
        //setSelectedCountry({ nombre: 'Persona 1', id: 1,estado:1 });
        //setValue("asignacion",tmp.asignacion)
    },[])
    const handleFilter = (qry : string) => {
        console.log(qry)
        //setListCountries([...listCountries,...[{name:"ignacio" + listCountries.length,code:'TT'}]])
    }
    const checkAddContact = (ilist : IUser[], idata: ITask) => {
        const list = ilist.filter( e => e.id == idata.userAsigned.id);
        if (list.length == 0) {
            setListContacts([...listContacts,idata.userAsigned])
        }
    }
    const handleShowDialog = () => {
        /*const obj = document.getElementById("asignacion");
        if (obj) {
            obj.setAttribute('style', 'width:100% !important');//hago que el select obreros tenga un with 100%
        }*/
        
        const p1 = fetchGenerics();
        const p2 = fetchContacts();
        Promise.all([p1, p2])
            .then(res => {
                const r1 = res[0];
                const r2 = res[1];
                if (data.id) {
                    checkAddContact(r2,data);
                    
                }
                
                // Aquí puedes trabajar con los resultados de las dos promesas
                
                if (data.id == undefined) {
            
                    reset({description:"",iniDate:new Date(),dueDate:null,userAsigned:null,area:null} as any)
                } else {
                    reset(data)
                    setValue("dueDate",new Date(data.dueDate))
                    //setValue("userAsigned",{ username: data.userAsigned.username, id: data.userAsigned.id,isActive:1 } as any)
                }
            })
            .catch(error => {
                // Aquí puedes manejar el error si alguna de las promesas falla
                console.error(error);
            });
        
        
    }
    /*const handleChangeDropdown = (a : IUser) => {
        if (typeof a === 'string') {
            console.log(a + "str")
            setValue("userAsigned",{username:a, id:null,isActive:1} as any)
            setVisibleTel(true)
        } else {
            console.log(typeof a)
            setValue("userAsigned",a)
            setVisibleTel(false)
        }
        
     
    }*/

   
      /*useEffect(() => {
        // reset form with user data
        reset(task);
        if (typeof task?.userAsigned === 'string') {
            setVisibleTel(true)
        } else {
            setVisibleTel(false)
        }
      }, [task]);*/

      useEffect(() => {
        if (typeof refAsign.current?.props.value === 'string') {
            setVisibleTel(true)
        } else {
            setVisibleTel(false)
        }
      }, [watchAsignacion]);

    const saving = () => {
        setButtonClassesSave([...buttonClassesSave.filter(className => className !== 'pi-save'), 'pi-spin','pi-spinner']);

    }
    const saved = () => {
        setButtonClassesSave([...buttonClassesSave, 'pi-save']);
        setButtonClassesSave(buttonClassesSave.filter(className => (className !== 'pi-spin' && className !== 'pi-spinner')));
    }
  
    return (<>
        <Dialog header="Nueva Tarea" visible={visible} onShow={handleShowDialog} maximizable style={{ width: (isMobile ? '100%' : "50vw" )}} onHide={() => setVisible(false)}>
            <form onSubmit={handleSubmit(onSubmit)}>
            {/*<Button label="Set" className="w-auto mt-3" onClick={(e) => {e.preventDefault();setValue("userAsigned",{ username: 'Persona 1', id: 1,isActive:1 } as any)}}/>*/}
                <div className="grid formgrid p-fluid mt-2">
                    <div className="field mb-4 col-12">
                        <label htmlFor="descripcion" className="font-medium text-900">Tarea</label>
                        <InputTextarea id="descripcion" rows={5}  {...register("description", { required: true })} className={errors.description ? "p-invalid" : ""} />
                        {errors.description && <small className="p-error">La tarea es requerida</small>}
                    </div>
                    <div className="field mb-4 col-12 md:col-6">
                        {/*<label htmlFor="fecha" className="font-medium text-900">Fecha Vencimiento</label>
                        <Calendar id="fecha" showIcon locale="es"/>*/}
                        <Controller
                            name="dueDate"
                            control={control}
                            rules={{ required: 'Date is required.' }}
                            render={({ field, fieldState }) => (
                                <>
                                    <label htmlFor={field.name} className="font-medium text-900">Fecha Vencimiento</label>
                                    <Calendar 
                                    inputId={field.name} 
                                    value={field.value} 
                                    onChange={field.onChange} 
                                    dateFormat="dd/mm/yy"
                                    locale="es"
                                    className={classNames({ 'p-invalid': fieldState.error })} />
                                    
                                </>
                            )}
                        />
                        {errors.dueDate && <small className="p-error">Campo requerido</small>}
                    </div>
                    <div className="field mb-4 col-12 md:col-6">
                        <label htmlFor="userAsigned" className="font-medium text-900">Asignar a</label>
                        <Controller
                            name="userAsigned"
                            control={control}
                            rules={{ required: 'City is required.' }}

                            render={({ field, fieldState }) => (
                                <Dropdown
                                    ref={refAsign}
                                    value={field.value}
                                    optionLabel="username"
                                    placeholder="Seleccionar Asignación"
                                    name="username"
                                    options={listContacts}
                                    editable={true}
                                    onChange={(e) => field.onChange(e.value)}
                                    className={classNames({ 'p-invalid': fieldState.error })}
                                />
                    )}
                />

                        {errors.userAsigned && <small className="p-error">Campo requerido</small>}
                    </div>
                    <div className="field mb-4 col-6 md:col-6">
                        {/*<label htmlFor="fecha" className="font-medium text-900">Fecha Vencimiento</label>
                        <Calendar id="fecha" showIcon locale="es"/>*/}
                        <Controller
                            name="iniDate"
                            control={control}
                            rules={{ required: 'Date is required.' }}
                            render={({ field, fieldState }) => (
                                <>
                                    <label htmlFor={field.name} className="font-medium text-900">Fecha Inicio</label>
                                    <Calendar 
                                    inputId={field.name} 
                                    value={field.value} 
                                    onChange={field.onChange} 
                                    dateFormat="dd/mm/yy"
                                    locale="es"
                                    className={classNames({ 'p-invalid': fieldState.error })} />
                                    
                                </>
                            )}
                        />
                        {errors.iniDate && <small className="p-error">Campo requerido</small>}
                    </div>
                    {visibleTel && 
                    <>
                       <div className="field mb-4 col-12 md:col-6">
                           <label htmlFor="tarea" className="font-medium text-900">Télefono </label>
                           <Controller
                                name="telefono"
                                control={control}
                                rules={{required:true }}

                                render={({ field, fieldState }) => (
                                    <InputText  
                                    value={field.value ?? ''}
                                    ref={refPhone}  

                                    name="telefono"
                                    onChange={(e) => field.onChange(e.target.value)}
                                    className={errors.telefono ? "p-invalid" : ""} />
                                )}
                            />
                            {errors.telefono?.type === 'required' && <small className="p-error">Teléfono es requerido</small>}
                       </div>
                    </>
                    }
                    <div className="surface-border border-top-1 opacity-50 mb-3 col-12"></div>
                    <div className="field mb-4 col-12 md:col-6">
                        <label htmlFor="area" className="font-medium text-900">Obra</label>
                        <Controller
                            name="area"
                            control={control}
                            rules={{ required: 'City is required.' }}
                            render={({ field, fieldState }) => (
                                <Dropdown
                                    value={field.value}
                                    optionLabel="description"
                                    placeholder="Seleccionar Obra"
                                    name="test"
                                    options={listObras}
                                    onChange={(e) => field.onChange(e.value)}
                                    className={classNames({ 'p-invalid': fieldState.error })}
                                />
                                )}
                            />
                        {errors.area && <small className="p-error">Campo requerido</small>}
                    </div>
                    {/*<div className="field mb-4 col-12 md:col-6">
                        <label htmlFor="bio2" className="font-medium text-900">Bio</label>
                        <InputTextarea id="bio2"  rows={5} autoResize />
                    </div>
                    <div className="surface-border border-top-1 opacity-50 mb-3 col-12"></div>
                    <div className="field mb-4 col-12 md:col-6">
                        <label htmlFor="email2" className="font-medium text-900">Email</label>
                        <InputText id="email2" type="text" />
                    </div>
                    <div className="field mb-4 col-12 md:col-6">
                        <label htmlFor="country2" className="font-medium text-900">Country</label>
                        <Dropdown inputId="country2" options={countries} value={country2} onChange={(e) => setCountry2(e.value)} optionLabel="name" filter filterBy="name" showClear placeholder="Select a Country" itemTemplate={(country) => <div className="flex align-items-center">
                            <img src="assets/images/blocks/flag/flag_placeholder.png" className={`mr-2 flag flag-${country.code.toLowerCase()}`} style={{ width: '18px' }} alt={country.name} />
                            <div>{country.name}</div>
                        </div>} />

                    </div>
                    <div className="field mb-4 col-12 md:col-6">
                        <label htmlFor="city2" className="font-medium text-900">City</label>
                        <InputText id="city2" type="text" />
                    </div>
                    <div className="field mb-4 col-12 md:col-6">
                        <label htmlFor="state2" className="font-medium text-900">State</label>
                        <InputText id="state2" type="text" />
                    </div>*/}
                    {data.id == null && <>
                                    <div className="surface-border border-top-1 opacity-50 mb-3 col-12"></div>
                                    <div className="flex align-items-center mb-3">
                                        <Checkbox inputId="ingredient1" name="pizza" value="Cheese" onChange={(e : any) => setChkWts(e.checked)} checked={chkWts} />
                                        <label htmlFor="ingredient1" className="ml-2">Avisar por whatsapp</label>
                                    </div>
                                </>
                    }
                    <div className="surface-border border-top-1 opacity-50 mb-3 col-12"></div>
                    <div className="flex flex-wrap justify-content-center gap-3">
                        <Button label="Guardar Tarea" className="w-auto mt-3" icon={buttonClassesSave.join(' ')}/>
                        <Button type="button" label="Cerrar" severity='secondary' icon="pi pi-times" className="w-auto mt-3" onClick={() => setVisible(false)} />
                    </div>
                </div>
            </form>
        </Dialog>
    </>)
}
export default ModalTask;