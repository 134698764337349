import IUser from "../../../../commons/types/users/IUser";
import getAxiosInstance from "../../utils/AxiosUtil";
import BackendResponsePaginator from '../../../../commons/axios/BackendResponsePaginator'
import BackendResponse from '../../../../commons/axios/BackendResponse'
import ITask from "../../../../commons/types/tasks/ITask";
import TaskFilter from "../../../../commons/filters/tasks/TaskFilter"
import IResponsePaginator from "@/../../commons/types/response/IResponsePaginator";
class TaskAxios {

    private axiosInstance : any;
    private statusOk : number;
    constructor() {
        this.axiosInstance = getAxiosInstance();
        this.statusOk = 200;
    }

    public async createUpdate(task : ITask) : Promise<ITask> {
        try {
            const result : BackendResponse | any = await this.getAxios().post("api/task/saveUpdate",task);
        
            if (result.status != this.statusOk) {
                console.log(result.result)
                throw new Error(result.errorResponse?.message);
            }
            return result.data.data;
        } catch (e : any) {
            throw new Error(e.response.data.errorResponse.message);
        }
        
    }
    public async fetch(filter : TaskFilter) : Promise<IResponsePaginator<ITask[]>> {
        try {
            const result : BackendResponsePaginator<ITask[]> | any = await getAxiosInstance().post("/api/task/fetch",filter);

            if (result.status != this.statusOk) {
                console.log(result.result)
                throw new Error(result.errorResponse?.message);
            }
            return result.data.data;
        } catch (e : any) {
            throw new Error(e.response.data.errorResponse.message);
        }
        
    }
    public async delete(id : number) : Promise<void> {
        console.log(id)
        try {
            if (id == null || id<1) {
                throw new Error("Id incorrecto");
            }
            const obj : ITask = {id:id,updateFields:"isActive",isActive:0} as any
            await this.getAxios().post("/api/task/saveUpdate",obj);
        
        } catch (e : any) {
            console.error(e)
            throw new Error(e.response.data.errorResponse.message);
        }
    }

   
    private getAxios() {
        if (!this.axiosInstance) {
            this.axiosInstance = getAxiosInstance();
        }
        return this.axiosInstance;
    }
}

const taskAxiosInstance = new TaskAxios();
export default taskAxiosInstance;