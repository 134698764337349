import React, { useContext, useState } from 'react';
import { BrowserRouter as Router,  Route,  BrowserRouter, Routes , Navigate, useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import AuthContext, { AuthProvider } from './componentes/authProvider/AuthContext';
import JWTDeCode from './types/JWTDecode';

import Blank from './pantallas/Blank';
import Main from './pantallas/Main';
import Tasks from './pantallas/tasks/TasksList';
import ContactsList from './pantallas/users/ContactsList';
import UsersList from './pantallas/users/UsersList';
import GenericList from './pantallas/generics/GenericList';

const Login = React.lazy(() => import('./pantallas/login/Login'))
//const UsersList = React.lazy(() => import('./pantallas/users/UsersList'))
function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const { token } = useContext(AuthContext);
  // Función para validar el token JWT
  const validateToken = () => {
    console.log("toek" + token)
    if (token) {
      /*const decodedToken : JWTDeCode = jwt_decode(token);
      if (decodedToken.exp < Date.now() / 1000) {
        setIsAuthenticated(false);
      } else {
        setIsAuthenticated(true);
      }*/
      setIsAuthenticated(true);
      console.log("token" + token)
      
    } else {
      setIsAuthenticated(false);
      //document.location.href = "/"
    }
  };

  // Llamar a la función de validación cuando se monte el componente
  React.useEffect(() => {
    validateToken();
  }, []);
  React.useEffect(() => {
    validateToken();
  }, [token]);
  return (
    <BrowserRouter>
    <React.Suspense fallback={<div>loading</div>}>
      <Routes>

          {!isAuthenticated && <Route  path={`${process.env.PUBLIC_URL}/*`} element={<Login setIsAuthenticated={setIsAuthenticated} />}></Route>}
          {isAuthenticated && <>
            <Route path={`${process.env.PUBLIC_URL}/`} element={<Main />}>
              <Route index element={
                                                        <div><RequireAuth setIsAuthenticated={setIsAuthenticated} isAuthenticated={isAuthenticated} redirectTo="/login">
                                                          <Tasks />
                                                        </RequireAuth></div>
                                                        }/>
              <Route path={`${process.env.PUBLIC_URL}/contactos`} element={
                                                        <div><RequireAuth setIsAuthenticated={setIsAuthenticated} isAuthenticated={isAuthenticated} redirectTo="/login">
                                                          <ContactsList />
                                                        </RequireAuth></div>
                                                        }/>
              <Route path={`${process.env.PUBLIC_URL}/usuarios`} element={
                                                        <div><RequireAuth setIsAuthenticated={setIsAuthenticated} isAuthenticated={isAuthenticated} redirectTo="/login">
                                                          <UsersList />
                                                        </RequireAuth></div>
                                                        }/>
              <Route path={`${process.env.PUBLIC_URL}/tasks`} element={
                                                        <div><RequireAuth setIsAuthenticated={setIsAuthenticated} isAuthenticated={isAuthenticated} redirectTo="/login">
                                                          <Tasks />
                                                        </RequireAuth></div>
                                                        }/>
              <Route path={`${process.env.PUBLIC_URL}/obras`} element={
                                                        <div><RequireAuth setIsAuthenticated={setIsAuthenticated} isAuthenticated={isAuthenticated} redirectTo="/login">
                                                          <GenericList />
                                                        </RequireAuth></div>
                                                        }/>
              <Route path={`${process.env.PUBLIC_URL}/*`} element={
                                                        <div><RequireAuth setIsAuthenticated={setIsAuthenticated} isAuthenticated={isAuthenticated} redirectTo="/login">
                                                          <Tasks />
                                                        </RequireAuth></div>
                                                        }/>
            </Route>
          </>
          }
          
          

      </Routes>
    </React.Suspense>
    </BrowserRouter>
  );
}

interface RequireAuthProps {
  children: React.ReactNode;
  redirectTo: string;
  isAuthenticated:boolean;
  setIsAuthenticated:(a:boolean) => void
}

const RequireAuth: React.FC<RequireAuthProps> = ({
  children,
  redirectTo,
  isAuthenticated,
  setIsAuthenticated
}) => {
  const a = localStorage.getItem('token');
  if (!a) {
    setIsAuthenticated(false);
  }
  return a !== '' && a !== null ? (
    <div>{children}</div>
  ) : (
    <Navigate to={redirectTo} />
  );
 /* const a = localStorage.getItem('token');
  setIsAuthenticated(a !== '' && a !== null);
  return a !== '' && a !== null ? (
    <div>{children}</div>
  ) : (
    <Navigate to={redirectTo} />
  );*/
};
export default App;