import {useState} from 'react';
import { Outlet } from 'react-router-dom';

interface Props { }


const Blank: React.FC<Props> = props => {
    return (<>
    blank
    </>)
}
export default Blank;