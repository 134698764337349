import React, { createContext, useEffect, useState } from "react";
import { ReactNode } from 'react';
import IUser from "../../../../commons/types/users/IUser";
import getAxiosInstance from "../../utils/AxiosUtil";


interface AuthContextData {
    token: string | null;
    setTokenStorage: (token: string | null) => void;
    logout: () => void;
    getUser: () => Promise<IUser | null>;
    userLogued: IUser | null;
  }
  interface AuthProviderProps {
    children: ReactNode;
  }
  const AuthContext = createContext<AuthContextData>({
    token: null,
    setTokenStorage: (token: string | null) => {console.log("pasa" + token)},
    logout: () => {},
    getUser: async () => {return null},
    userLogued: null
  });
  
  export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [token, setToken]                 = useState<string | null>(localStorage.getItem('token'));
    const [userLogued,setUserLogued]        = useState<IUser>({} as IUser);
    const getUser = async () : Promise<IUser | null> => {

      if (!userLogued.id && token) {
        const response : {data:{data: IUser}} = await getAxiosInstance().get("/api/user/getUserLogued")
        setUserLogued(response.data.data);
        return response.data.data;
      } else if (userLogued.id != null && userLogued.id>0) {
        return userLogued;
      }
      return null;
      }

    const setTokenStorage = (a:string | null) => {
        setToken(a);
        localStorage.setItem('token',(a ? a : ''))
    }
    /*const [windowSize, setWindowSize]   = useState([
      window.innerWidth,
      window.innerHeight,
    ]);*/
    const logout = () => {
      localStorage.removeItem ('token')
      document.location.href = "/login";
    }

    
    return (
      <AuthContext.Provider value={{ token, setTokenStorage,logout,getUser,userLogued}}>
        {children}
      </AuthContext.Provider>
    );
  };
  
  export default AuthContext;