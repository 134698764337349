import React from 'react';
import ReactDOM from 'react-dom';
import {useState,useEffect,useContext,createRef,  RefObject, useRef} from 'react';
import { DataTable, DataTableFilterMeta } from 'primereact/datatable';
import { Column, ColumnFilterElementTemplateOptions } from 'primereact/column';
import ITask from '../../../../commons/types/tasks/ITask';
import { compareDate, compareDates, fechaScreen, removeSpacesAndHyphens } from '../../utils/Utils';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast';
import ModalTask from '../../componentes/modals/tasks/ModalTask';
import PageTitle from '../../componentes/pageTitle/PageTitle';
import { DeviceContext } from '../../componentes/providers/DeviceProvider';
import taskAxiosInstance from '../../componentes/axios/TaskAxios';
import TaskFilter from '../../../../commons/filters/tasks/TaskFilter';
import EWorkflow from '@../../../../commons/enums/workflow/EWorkflow';
import { Skeleton } from 'primereact/skeleton';
import ModalTaskWts from '../../componentes/modals/tasks/ModalTaskWts';
import { Calendar, CalendarChangeEvent } from 'primereact/calendar';
import UpdateFields from '../../../../commons/types/genericData/UpdateFields';
import { useLocation, useNavigate } from "react-router-dom";
import { PaginatorPageChangeEvent } from 'primereact/paginator';
import IResponsePaginator from '@/../../commons/types/response/IResponsePaginator';
import EPages from '@../../../../commons/types/response/EPages';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from 'primereact/api';
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';
import IUser from '@/../../commons/types/users/IUser';
import userAxiosInstance from '../../componentes/axios/UserAxios';
import AuthContext from '../../componentes/authProvider/AuthContext';
interface Props { }

interface IBtn {
    id: number
    btn : RefObject<Menu>
}
const Tasks: React.FC<Props> = props => {
    const [data,setData]                    = useState<ITask[]>([]);
    const [user,setUser]                    = useState<IUser | null>(null);
    const [dataClicked,setDataClicked]      = useState<ITask>({} as any);
    const [taskWtsNotify,setTaskWtsNotify]  = useState<ITask>({} as any);
    const [refBtns,setRefBtns]              = useState<IBtn[]>([]);
    const { getUser,userLogued }                    = useContext(AuthContext);
    const [visible,setVisible]                      = useState<boolean>(false);
    const [visibleWtsNotifi,setVisibleWtsNotifi]    = useState<boolean>(false);
    const { windowWidth ,isMobile}                  = useContext(DeviceContext);
    const [loading,setLoading]                      = useState<boolean>(true);
    const [date, setDate]                           = useState<string | Date | Date[] | null>(new Date());
    const toast                                     = useRef<Toast>(null);  
    const {search}                                  = useLocation();
    const [rows,setRows]                            = useState<number>(EPages["pageSize"]);
    const [first,setFirst]                          = useState<number>(0);
    const [totalRows,setTotalRows]                  = useState<number>(0);
    const [filterAsign,setFilterAsign]              = useState<IUser[]|null>(null);
    const [listUserFilter,setListUserFilter]        = useState<IUser[]>([]);
    const navigate                                  = useNavigate();
    const [filters, setFilters] = useState({
        "userAsigned.username": { value: null, matchMode: FilterMatchMode.CUSTOM }
    });
    const [globalFilterValue, setGlobalFilterValue] = useState<string>('');
    const showToast = (obj : any) => {
        toast.current?.show(obj);
    };
    let fetchFilter : {userAsigned:IUser[] | null} = {userAsigned:null};

    const items = [
        {
            label: 'Options',
            items: [
                {
                    label: 'Editar',
                    icon: 'pi pi-pencil',
                    command: () => {
                        setVisible(true)
                    }
                },
                {
                    label: 'Eliminar',
                    icon: 'pi pi-times',
                    command: () => {
                        handleDelete();
                    }
                },
                {
                    label: 'Finalizada',
                    icon: 'pi pi-check',
                    command: () => {
                        handleSetCompleted();
                    }
                }
            ]
        }
    ];
    const items2 = [
        {
            label: 'Options',
            items: [
                {
                    label: 'Editar',
                    icon: 'pi pi-pencil',
                    command: () => {
                        setVisible(true)
                    }
                },
                {
                    label: 'Eliminar',
                    icon: 'pi pi-times',
                    command: () => {
                        handleDelete();
                    }
                },
                {
                    label: 'Remover finalizada',
                    icon: 'pi pi-check',
                    command: () => {
                        handleRemoveCompleted();
                    }
                }
            ]
        }
    ];
    const fetchTasks = async (first?:number,rows?:number) : Promise<void> => {

        try {
            
        
            setLoading(true);
            const response : IResponsePaginator<ITask[]> = await taskAxiosInstance.fetch(getFilter(first,rows));
            setLoading(false);
            console.log(response)
            let list2 : IBtn[] = [];
            response.data.forEach(e => {
                const tmp : RefObject<Menu> = createRef<Menu>();
                const item : IBtn = {id: e.id, btn: tmp}
                list2.push(item); 
            })
            setRefBtns([...refBtns,...list2]);
            setData(response.data);
            setTotalRows(response.totalRecords);
        } catch (e) {
            console.error(e);
        }
      
  }
  const handleSetCompleted = async () => {
    if (user?.id === dataClicked.author.id) {
        const data = dataClicked as (ITask & UpdateFields);
        data.updateFields = "workflow"
        data.workflow = EWorkflow["completed"] as any;
        await taskAxiosInstance.createUpdate(data);
        await fetchTasks();
    } else {
        showToast({ severity: 'warn',  detail: <div>Unicamente el autor puede dar por finalizada la tarea</div>});
    }
    
  }
  const handleRemoveCompleted = async () => {
    const data = dataClicked as (ITask & UpdateFields);
    data.updateFields = "workflow"
    data.workflow = null;
    await taskAxiosInstance.createUpdate(data);
    await fetchTasks();
  }
  
  const fetchTasksWrapper = async (showWts:boolean,data:ITask) : Promise<void> => {
    if (showWts) {
        setVisibleWtsNotifi(true);
        setTaskWtsNotify(data);
    }
    await fetchTasks();
  }
  const getFilter = (first?:number,rows?:number) : TaskFilter => {
    const queryParams = new URLSearchParams(search);
    const q = queryParams.get('q');
    const filter : TaskFilter   = {} as any;
    filter.dueDate              = date as any;
    filter.descriptionLike      = (q && q.length>3 ? q : "");
    filter.currentPage          = (first ? first : 0);
    filter.pageSize             = (rows ? rows : EPages["pageSize"])
    filter.idUserAsignedIn      = fetchFilter.userAsigned ? fetchFilter.userAsigned.map(t => {return t.id}).join(', ') : null;
    if (compareDate((filter.dueDate ? filter.dueDate : new Date()),new Date())
        && filter.descriptionLike.length == 0 
        && filter.idUserAsignedIn == null) {
        filter.showOverdue = 1;
    }
    console.log(filter)
    console.log(filterAsign);
    return filter;
  }
  const handleDelete = async () => {
    console.log(user?.id + " - " + dataClicked.author.id)
    if (dataClicked.id && user?.id === dataClicked.author.id) {
        await taskAxiosInstance.delete(dataClicked.id);
        showToast({ severity: 'info',  detail: <div>Tarea <b>{dataClicked.description}</b> eliminada!</div>});
        await fetchTasks();
    } else {
        showToast({ severity: 'warn',  detail: <div>Solo el author de la tarea puede eliminarla</div>});
    }
    
}
    const fetchContacts = async () : Promise<IUser[]> => {
        const list = await userAxiosInstance.fetch();
        let res = list.map((t) => {
            t.username = (t.rol == 1 ? "*" + t.username : t.username)
            return t;
        })
        setListUserFilter(res);
        return res;
    }
    const getUserLocal = async () => {
        setUser(await getUser());
    }
    useEffect(() => {
        fetchContacts();
        getUserLocal();
    },[])

    useEffect(() => {
        
        navigate({
            pathname: "task",
            search: "q=",
          });
          setFilterAsign(null);
          fetchFilter.userAsigned = null;
        fetchTasks();
    },[date])
    const handleCleanFilter = () => {
        setDate(new Date());
    }
    useEffect(() => {/*se ejecuta cuando se modifica la qry url*/
        fetchTasks();
    },[search])

    const handleBtnClick = (data : ITask) => {
        setDataClicked(data);
    }
    const handleNewTask = () => {
        setVisible(true)
        setDataClicked({} as ITask);
    }
    /*const handleDateFormat = (data : ITask) => {
        return <>{fechaScreen(data.dueDate)}</>;
    }*/
    const handleDescription = (data : ITask) => {
        const descriptionLines = data.description.split('\n');
        return <>
                {descriptionLines.map((line, index) => (
                    <React.Fragment key={index}>
                    <b>{line}</b><br></br>
                    </React.Fragment>
                ))}
                Ini:{fechaScreen(data.iniDate)}<br></br>
                V:{fechaScreen(data.dueDate)}<br></br>
                Obra: {data.area.description}<br></br>
                Autor: {data.author.username}
            </>;
    }
    const handleActions = (data : ITask) => {
        
        const tmp : IBtn = refBtns.filter(e => e.id === data.id)[0];
        const itemTmp = (data.workflow == EWorkflow.completed ? items2 : items)
        return <>
                    {
                        user?.id === data.author.id &&
                            <>
                                <Menu model={itemTmp} popup ref={tmp.btn} />
                                <Button size="small" icon="pi pi-bars" onClick={(e) => {tmp.btn && tmp.btn.current &&  tmp.btn.current.toggle(e);handleBtnClick(data)}} ></Button>
                            </>
                    }
                    
                </>
    }
    const templateAsignacion = (data :ITask) => {
        return data.userAsigned.username;
    }
    
    const templateObra = (data :ITask) => {
        return data.area.description;
    }
    const TemplateWts = (data:ITask) => {
        if (isMobile) {
          return (
            <>
              <Button
                icon="pi pi-whatsapp"
                label=""
                className="p-button-rounded p-button-success p-button-outlined"
                onClick={() =>
                  window.open(
                    `https://api.whatsapp.com/send?phone=${removeSpacesAndHyphens(data.userAsigned.telefono)}&text=La tarea${(encodeURIComponent('\r\n' + data.description + '\r\nvence el '))} ${fechaScreen(
                        data.dueDate
                    )}`,
                    '_blank'
                  )
                }
              />
            </>
          );
        }
        return (
          <>
            <Button
              icon="pi pi-whatsapp"
              label=""
              className="p-button-rounded p-button-success"
              onClick={() =>
                window.open(
                  `https://web.whatsapp.com/send?phone=${removeSpacesAndHyphens(data.userAsigned.telefono)}&text=La tarea${(encodeURIComponent('\r\n' + data.description + '\r\nvence el '))} ${fechaScreen(
                    data.dueDate
                  )}`,
                  '_blank'
                )
              }
            />
          </>
        );
    };

    const handleMoveDate = (nro:number) => {
        
        if (date) {
            const newDate = new Date(date as any); // crear una nueva instancia de Date
            newDate.setDate(newDate.getDate() + nro); // incrementar un día
            setDate(newDate); // actualizar el estado con la nueva fecha
        }
        
    }

    const HeaderPanel = () => {
        return <>
                <div className="grid formgrid p-fluid mt-2" style={{width:"99%"}}>
                    <div className="field mb-4 md:col-3">
                        <Button size="small" label='Nueva Tarea' icon="pi pi-plus" onClick={(e) => {handleNewTask()}} className="p-button-sm mb-3"></Button>
                    </div>
                    <div className="field mb-4 md:col-1">
                        <Button size="small" label='<' severity="secondary" className="p-button-sm mb-3" onClick={() => handleMoveDate(-1)}></Button>
                    </div>
                    <div className="field mb-4 md:col-3">
                    
                        <Calendar  showIcon={true} value={date} onChange={(e: CalendarChangeEvent) => setDate(e.value as any)} className="p-inputtext-sm p-calendar-sm" locale="es"/>
                    </div>
                    <div className="field mb-4 md:col-1">
                        <Button size="small" label='>' severity="secondary" className="p-button-sm mb-3" onClick={() => handleMoveDate(1)}></Button>
                    </div>
                </div>
            </>;
    }

    const rowClass = (data : ITask) => {
        const rowDate = new Date(data.dueDate)
        const hoy = new Date();
        console.log(rowDate,hoy, data.workflow )
        return ((compareDates(rowDate,hoy) == 2 && data.workflow === null) ? "bg-red-300" : (data.workflow === EWorkflow["completed"] ? "bg-gray-400" : ""));
    };

    const onPageChange = (event: PaginatorPageChangeEvent) => {
        setFirst(event.first);
        setRows(event.rows);
        fetchFilter.userAsigned = filterAsign;
        fetchTasks(event.first,event.rows);
    };
    /**
     * aplica el filtro de userasigned del datatable
     * @param value 
     */
    const handlerFilter = (value: any ) => {
        fetchFilter.userAsigned = value;
        setFilterAsign(value);
        fetchTasks();
    }

    /**
     * filtro de datatable con los contactos/usuarios
     * @param options 
     * @returns 
     */
    const balanceFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
        return (
            <>
                <div className="card flex justify-content-center">
                <MultiSelect value={filterAsign} onChange={(e: MultiSelectChangeEvent) => handlerFilter(e.value)} options={listUserFilter} optionLabel="username" 
                    filter /*onFilter={(t) => handleFil(t)}*/ placeholder="Filtrar por contacto" className="w-full md:w-20rem" />
        </div>
            </>
        );
    };
    /*const balanceFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
        return <InputText value={options.value} onChange={(e: any) => handlerFilter(e.value,1)} />;
    };*/

    /*const initFilters = () => {
        setFilters({
            "userAsigned.username": { value: null, matchMode: FilterMatchMode.CUSTOM }
        });
        setGlobalFilterValue('');
    };*/

    const header = (
        <div className="flex flex-wrap align-items-center justify-content-between ">
            <span className=" text-900 font-bold">Lista de Tareas</span>
            <Button onClick={handleCleanFilter} icon="pi pi-eraser" rounded raised  size='small' style={{width:"1.657rem",height:"1.657rem"}} severity="secondary" title='Limpiar Filtros'/>
        </div>
    );

    const headerAsignTo = (
        <span>Asignado a: {filterAsign && filterAsign.map((t) => t.username).join(", ")}</span>

    );
    return (<>
        <ModalTask onChange={function () {}} visible={visible} setVisible={setVisible} data={dataClicked} toast={showToast} fetch={fetchTasksWrapper}/>
        <ModalTaskWts visible={visibleWtsNotifi} setVisible={setVisibleWtsNotifi} task={taskWtsNotify} />
        <Toast ref={toast} />
        <PageTitle titleMain='Tareas'  titleSecondary='Tareas del día' />
        
        <HeaderPanel/>
        <div className="overflow-x-auto" style={{maxWidth:windowWidth-15}}>
        

            <DataTable value={data} first={first} paginator showGridlines  rowClassName={rowClass}  header={header} 
                    loading={loading} totalRecords={totalRows} lazy={true} rows={rows} 
                    rowsPerPageOptions={[5, 10, 25, 50]} onPage={(e:any) => {onPageChange(e)}}
                    emptyMessage="No se encontraron tareas"
                    currentPageReportTemplate="Visualizando {first} hasta {last} de {totalRecords}"
                    paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                    filters={filters} filterDisplay="menu" globalFilterFields={['userAsigned.username']}
                    >
                <Column field="description" body={handleDescription} header="Tarea"></Column>
                <Column body={templateAsignacion} header={headerAsignTo} field="userAsigned.username" filterClear=" " filterApply=" " filter showFilterMatchModes={false}  filterElement={balanceFilterTemplate}></Column>
                <Column header="WTS" body={TemplateWts}></Column>
                <Column body={handleActions} header="" style={{ width: '10px' }}></Column>
            </DataTable> 
        </div>
    </>)
}
export default Tasks;