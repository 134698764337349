import {useContext, useState,useEffect, useRef} from 'react';
import { locale, addLocale, updateLocaleOption, updateLocaleOptions, localeOption, localeOptions } from 'primereact/api';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { DeviceContext } from '../../providers/DeviceProvider';
import { InputText } from 'primereact/inputtext';
import { Controller, useForm } from 'react-hook-form';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import IUser from '../../../../../commons/types/users/IUser';
import userAxiosInstance from '../../axios/UserAxios';
interface Props { 
    visible:boolean
    setVisible: (a:boolean) => void
    onChange:() => void,
    data: IUser;
    toast: (obj:any) => void
    fetchUsers: (irol: number | null) => Promise<void>;
}



const ModalUser: React.FC<Props> = ({visible,setVisible,data,toast,fetchUsers} : Props)  => {
    const { reset,trigger,control,register, handleSubmit, watch,setValue, formState: { errors } } = useForm<IUser>();
    const { isMobile }                          = useContext(DeviceContext);
    const [buttonClassesSave, setButtonClassesSave]     = useState(['pi', 'pi-save']);//pi pi-save pi-spin
    const createUser = async (user : IUser) : Promise<void> => {
        try {
            saving();
            user.rol = 2;
            const userCreated = await userAxiosInstance.createUser(user);
            saved();
            console.log(buttonClassesSave)
            fetchUsers(2)
            setVisible(false)
            toast({ severity: 'info',  detail: <div>El usuario <b>{user.username}</b> fue {user.id == null ? 'creado' : 'editado'}</div>});
        } catch (e : any) {
            toast({ severity: 'error',  detail: e.message });
            saved();
            console.error(e);
        }
        
    }
    const saving = () => {
        setButtonClassesSave([...buttonClassesSave.filter(className => className !== 'pi-save'), 'pi-spin','pi-spinner']);

    }
    const saved = () => {
        setButtonClassesSave([...buttonClassesSave, 'pi-save']);
        setButtonClassesSave(buttonClassesSave.filter(className => (className !== 'pi-spin' && className !== 'pi-spinner')));
    }

    const onSubmit = (data : IUser) => {

        createUser(data);
    
    }
    const handleShow = () => {
        if (data.id == undefined) {
            
            reset({username:"",login:"",password:"",email:"",telefono:""})
        } else {
            reset(data)
        }
    }

   


    return (<>
        <Dialog header="Nuevo Contacto" visible={visible} maximizable onShow={handleShow} style={{ width: (isMobile ? '100%' : "50vw" )}} onHide={() => setVisible(false)}>
            <form onSubmit={handleSubmit(onSubmit)}>
            {/*<Button label="Set" className="w-auto mt-3" onClick={(e) => {e.preventDefault();setValue("asignacion",{ nombre: 'Persona 1', id: 1,estado:1 })}}/>*/}
                <div className="grid formgrid p-fluid mt-2">
                    <div className="field mb-4 col-12">
                        <label htmlFor="username" className="font-medium text-900">Nombre</label>
                        <InputText  id="username" type="text" {...register("username", { required: true })} className={errors.username ? "p-invalid" : ""} />
                        {errors.username && <small className="p-error">Campo requerido</small>}
                    </div>



                    <div className="field mb-4 col-12 md:col-6">
                        <label htmlFor="tarea" className="font-medium text-900" >Télefono </label>
                        <InputText  id="telefono" type="text" {...register("telefono", { required: true })} className={errors.telefono ? "p-invalid" : ""} />
                        {errors.telefono && <small className="p-error">Campo requerido</small>}
                    </div>


                    <div className="surface-border border-top-1 opacity-50 mb-3 col-12"></div>

                   
                    <div className="flex flex-wrap justify-content-center gap-3">
                        <Button label="Guardar" className="w-auto mt-3" icon={buttonClassesSave.join(' ')}></Button>
                        <Button type="button" label="Cerrar" severity='secondary' icon="pi pi-times" className="w-auto mt-3" onClick={() => setVisible(false)} />
                    </div>
                </div>
            </form>
        </Dialog>
    </>)
}
export default ModalUser;