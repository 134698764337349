import React, { useRef, useState } from "react";

import { InputText } from "primereact/inputtext";
import { Badge } from "primereact/badge";
import { StyleClass } from "primereact/styleclass";
import { Ripple } from "primereact/ripple";
import { SelectButton } from "primereact/selectbutton";
import { Button } from "primereact/button";
import { Outlet } from "react-router-dom";
interface Props { }


const Main: React.FC<Props> = props => {
    const rutaImagen = "assets/imgs/foto-perfil.jpg";
    const imagen = require(`../${rutaImagen}`);
    const btnRef40 = useRef(null);
    const btnRef41 = useRef(null);
    const btnRef42 = useRef(null);
    const btnRef43 = useRef(null);

    const btnRef1 = useRef(null);
    const btnRef2 = useRef(null);
    const btnRef3 = useRef(null);
    const btnRef4 = useRef(null);
    const btnRef5 = useRef(null);
    return (<>
    
        
    <div className="min-h-screen flex relative lg:static surface-ground">
        <div id="app-sidebar-9" className="h-screen surface-section hidden lg:block flex-shrink-0 absolute lg:static left-0 top-0 z-1 border-right-1 surface-border w-18rem lg:w-7rem select-none">
            <div className="flex flex-column h-full">
                <div className="flex align-items-center justify-content-center flex-shrink-0" style={{ height: '60px' }}>
                    <img src="/imgs/logo.svg" alt="Image" style={{maxWidth:"100px"}}/>
                </div>
                <div className="mt-3">
                    <ul className="list-none p-0 m-0">
                        <li>
                            <a className="p-ripple flex flex-row lg:flex-column align-items-center cursor-pointer p-3 lg:justify-content-center text-cyan-600 border-left-2 border-cyan-600 hover:border-300 transition-duration-150 transition-colors">
                                <i className="pi pi-home mr-2 lg:mr-0 mb-0 lg:mb-2 text-base lg:text-2xl"></i>
                                <span className="font-medium inline text-base lg:text-xs lg:block">Home</span>
                                <Ripple />
                            </a>
                        </li>
                        <li>
                            <a className="p-ripple flex flex-row lg:flex-column align-items-center cursor-pointer p-3 lg:justify-content-center text-600 border-left-2 border-transparent hover:border-300 transition-duration-150 transition-colors">
                                <i className="pi pi-search mr-2 lg:mr-0 mb-0 lg:mb-2 text-base lg:text-2xl"></i>
                                <span className="font-medium inline text-base lg:text-xs lg:block">Search</span>
                                <Ripple />
                            </a>
                        </li>
                        <li>
                            <a className="p-ripple flex flex-row lg:flex-column align-items-center cursor-pointer p-3 lg:justify-content-center text-600 border-left-2 border-transparent hover:border-300 transition-duration-150 transition-colors">
                                <i className="pi pi-users mr-2 lg:mr-0 mb-0 lg:mb-2 text-base lg:text-2xl"></i>
                                <span className="font-medium inline text-base lg:text-xs lg:block">Team</span>
                                <Ripple />
                            </a>
                        </li>
                        <li className="relative">
                            <StyleClass nodeRef={btnRef40} selector="@next" enterClassName="hidden" leaveToClassName="hidden" hideOnOutsideClick>
                                <a ref={btnRef40} className="p-ripple flex flex-row lg:flex-column align-items-center cursor-pointer p-3 lg:justify-content-center text-600 border-left-2 border-transparent hover:border-300 transition-duration-150 transition-colors" >
                                    <i className="pi pi-chart-line mr-2 lg:mr-0 mb-0 lg:mb-2 text-base lg:text-2xl p-overlay-badge"><Badge severity="danger">3</Badge></i>
                                    <span className="font-medium inline text-base lg:text-xs lg:block">Reports</span>
                                    <i className="pi pi-chevron-down ml-auto lg:hidden"></i>
                                    <Ripple />
                                </a>
                            </StyleClass>
                            <ul className="list-none pl-3 pr-0 py-0 lg:p-3 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out static border-round-right lg:absolute left-100 top-0 z-1 surface-overlay shadow-none lg:shadow-2 w-full lg:w-15rem">
                                <li>
                                    <StyleClass nodeRef={btnRef41} selector="@next" toggleClassName="hidden">
                                        <a ref={btnRef41} className="p-ripple flex align-items-center cursor-pointer p-3 hover:surface-100 hover:text-900 border-round text-600 hover:text-700 transition-duration-150 transition-colors">
                                            <i className="pi pi-chart-line mr-2"></i>
                                            <span className="font-medium">Revenue</span>
                                            <i className="pi pi-chevron-down ml-auto"></i>
                                            <Ripple />
                                        </a>
                                    </StyleClass>
                                    <ul className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                                        <li>
                                            <a className="p-ripple flex align-items-center cursor-pointer p-3 hover:surface-100 hover:text-900 border-round text-600 hover:text-700 transition-duration-150 transition-colors">
                                                <i className="pi pi-table mr-2"></i>
                                                <span className="font-medium">View</span>
                                                <Ripple />
                                            </a>
                                        </li>
                                        <li>
                                            <a className="p-ripple flex align-items-center cursor-pointer p-3 hover:surface-100 hover:text-900 border-round text-600 hover:text-700 transition-duration-150 transition-colors">
                                                <i className="pi pi-search mr-2"></i>
                                                <span className="font-medium">Search</span>
                                                <Ripple />
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a className="p-ripple flex align-items-center cursor-pointer p-3 hover:surface-100 hover:text-900 border-round text-600 hover:text-700 transition-duration-150 transition-colors">
                                        <i className="pi pi-chart-line mr-2"></i>
                                        <span className="font-medium">Expenses</span>
                                        <Ripple />
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a className="p-ripple flex flex-row lg:flex-column align-items-center cursor-pointer p-3 lg:justify-content-center text-600 border-left-2 border-transparent hover:border-300 transition-duration-150 transition-colors">
                                <i className="pi pi-calendar mr-2 lg:mr-0 mb-0 lg:mb-2 text-base lg:text-2xl"></i>
                                <span className="font-medium inline text-base lg:text-xs lg:block">Events</span>
                                <Ripple />
                            </a>
                        </li>
                        <li>
                            <a className="p-ripple flex flex-row lg:flex-column align-items-center cursor-pointer p-3 lg:justify-content-center text-600 border-left-2 border-transparent hover:border-300 transition-duration-150 transition-colors">
                                <i className="pi pi-cog mr-2 lg:mr-0 mb-0 lg:mb-2 text-base lg:text-2xl"></i>
                                <span className="font-medium inline text-base lg:text-xs lg:block">Options</span>
                                <Ripple />
                            </a>
                        </li>
                    </ul>
                </div>
                {/*<div className="mt-auto">
                    <hr className="mb-3 mx-3 border-top-1 border-none surface-border" />
                    <a className="p-ripple m-3 flex flex-row lg:flex-column align-items-center cursor-pointer p-3 lg:justify-content-center hover:surface-200 border-round text-600 transition-duration-150 transition-colors">
                        <img src="/imgs/logo.svg" className="mr-2 lg:mr-0" style={{ width: '32px', height: '32px' }} />
                        <span className="font-medium inline lg:hidden">Amy Elsner</span>
                        <Ripple />
                    </a>
                </div>*/}
            </div>
        </div>
        <div className="min-h-screen flex flex-column relative flex-auto">
            <div className="flex justify-content-between align-items-center px-5 surface-section relative lg:static border-bottom-1 surface-border" style={{ height: '60px' }}>
                <div className="flex">
                    <StyleClass nodeRef={btnRef42} selector="#app-sidebar-9" enterClassName="hidden" enterActiveClassName="fadeinleft" leaveToClassName="hidden" leaveActiveClassName="fadeoutleft" hideOnOutsideClick>
                        <a ref={btnRef42} className="p-ripple cursor-pointer block lg:hidden text-700 mr-3">
                            <i className="pi pi-bars text-4xl"></i>
                            <Ripple />
                        </a>
                    </StyleClass>
                    <span className="p-input-icon-left">
                        <i className="pi pi-search"></i>
                        <InputText className="border-none w-10rem sm:w-20rem" placeholder="Search" />
                    </span>
                </div>
                <StyleClass nodeRef={btnRef43} selector="@next" enterClassName="hidden" enterActiveClassName="fadein" leaveToClassName="hidden" leaveActiveClassName="fadeout" hideOnOutsideClick>
                    <a ref={btnRef43} className="p-ripple cursor-pointer block lg:hidden text-700">
                        <i className="pi pi-ellipsis-v text-2xl"></i>
                        <Ripple />
                    </a>
                </StyleClass>

                <ul className="list-none p-0 m-0 hidden lg:flex lg:align-items-center select-none lg:flex-row
    surface-section border-1 lg:border-none surface-border right-0 top-100 z-1 shadow-2 lg:shadow-none absolute lg:static">
                                <li className="lg:relative">
                                    <StyleClass
                                        nodeRef={btnRef2}
                                        selector="@next"
                                        enterClassName="hidden"
                                        enterActiveClassName="scalein"
                                        leaveToClassName="hidden"
                                        leaveActiveClassName="fadeout"
                                        hideOnOutsideClick
                                    >
                                        <a
                                            ref={btnRef2}
                                            className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full"
                                        >
                                            <img src={imagen} className="mr-3 lg:mr-0" style={{ width: '32px', height: '32px' }} />
                                            <Ripple />
                                        </a>
                                    </StyleClass>
                                    <ul style={{left:"-80px"}} className="list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:border-1 border-50 lg:absolute surface-overlay hidden origin-top w-full lg:w-15rem cursor-pointer">
                                        <li>

                                                        <div className="flex border-bottom-1 surface-border pb-4 m-1">
                                                            <img src={imagen} style={{ width: '70px', height: '70px' }} className="mr-3" alt="1" />
                                                            <div className="flex flex-column align-items-start">
                                                                <span className="text-lg text-900 font-medium mb-1">Daniel Szylder</span>
                                                                <span className="text-600 font-medium mb-2">Administrador</span>
                                                                <span className="bg-blue-50 text-blue-400 border-round inline-flex py-1 px-2 text-sm">Editar Foto</span>
                                                            </div>
                                                        </div>
                                        </li>
                                        <li className="">
                                            <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full">
                                                <i className="pi pi-user-plus mr-2"></i>
                                                <span className="font-medium">
                                                    Agregar Usuario
                                                </span>
                                                <Ripple />
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                        <a className="p-ripple flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer
                transition-duration-150 transition-colors">
                            <i className="pi pi-inbox text-base lg:text-2xl mr-2 lg:mr-0"></i>
                            <span className="block lg:hidden font-medium">Inbox</span>
                            <Ripple />
                        </a>
                    </li>
                    <li>
                        <a className="p-ripple flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer
                transition-duration-150 transition-colors">
                            <i className="pi pi-bell text-base lg:text-2xl mr-2 lg:mr-0 p-overlay-badge"><Badge severity="danger"></Badge></i>
                            <span className="block lg:hidden font-medium">Notifications</span>
                            <Ripple />
                        </a>
                    </li>
                </ul>
            </div>
            <div className="flex flex-column flex-auto">
                <div className="surface-section flex-auto" style={{padding:"5px"}}>
                    <Outlet />
                </div>
            </div>
        </div>
    </div>
        
    </>)
}
export default Main;