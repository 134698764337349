import { createContext, useState, useEffect, ReactNode } from 'react';

interface DeviceContextType {
    isMobile: boolean;
    windowWidth : number;
    windowHeight: number;
  }

export const DeviceContext = createContext<DeviceContextType>({
  isMobile: false,
  windowWidth:window.innerWidth,
  windowHeight:window.innerHeight
});
interface DeviceProviderProps {
    children: ReactNode;
  }
export const DeviceProvider = ({ children } : DeviceProviderProps) => {
  const [isMobile, setIsMobile]           = useState(false);
  const [windowWidth,setWindowWidth]      = useState<number>(window.innerWidth);
  const [windowHeight,setWindowHeight]    = useState<number>(window.innerHeight);
  useEffect(() => {
    const userAgent = navigator.userAgent;
    setIsMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent));

    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
      //setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);


  return (
    <DeviceContext.Provider value={{ isMobile,windowWidth,windowHeight  }}>
      {children}
    </DeviceContext.Provider>
  );
};