import IUser from "../../../../commons/types/users/IUser";
import getAxiosInstance from "../../utils/AxiosUtil";
import BackendResponse from '../../../../commons/axios/BackendResponse'

class UserAxios {

    private axiosInstance : any;
    private statusOk : number;
    constructor() {
        this.axiosInstance = getAxiosInstance();
        this.statusOk = 200;
        console.log(this.axiosInstance);
        console.log("PASA INSTANCE UserAxios ")
    }

    public async createUser(user : IUser) : Promise<IUser> {
        try {
            const result : BackendResponse | any = await this.getAxios().post("/api/user",user);
        
            if (result.status != this.statusOk) {
                console.log(result.result)
                throw new Error(result.errorResponse?.message);
            }
            return result.data.data;
        } catch (e : any) {
            throw new Error(e.response.data.errorResponse.message);
        }
        
    }
    public async fetch(irol? : number) : Promise<IUser[]> {
        try {
            const result : BackendResponse | any = await getAxiosInstance().post("/api/user/fetch" ,{rol:irol});

            if (result.status != this.statusOk) {
                console.log(result.result)
                throw new Error(result.errorResponse?.message);
            }
            return result.data.data;
        } catch (e : any) {
            throw new Error(e.response.data.errorResponse.message);
        }
        
    }
    public async delete(id : number) : Promise<void> {
        console.log(id)
        try {
            if (id == null || id<1) {
                throw new Error("Id incorrecto");
            }
            await this.getAxios().delete("/api/user/" + id);
        
        } catch (e : any) {
            console.error(e)
            throw new Error(e.response.data.errorResponse.message);
        }
    }

    public async changePassword(user : IUser) : Promise<void> {
        try {
            await this.getAxios().put("/api/user/changePassword",user);
        
            
        } catch (e : any) {
            throw new Error(e.response.data.errorResponse.message);
        }
        
    }
    private getAxios() {
        if (!this.axiosInstance) {
            this.axiosInstance = getAxiosInstance();
        }
        return this.axiosInstance;
    }
}

const userAxiosInstance = new UserAxios();
export default userAxiosInstance;