import {useContext, useState,useEffect, useRef} from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { DeviceContext } from '../../providers/DeviceProvider';
import { InputText } from 'primereact/inputtext';
import UpdateFields from '../../../../../commons/types/genericData/UpdateFields';
import IGenericData from '../../../../../commons/types/genericData/IGenericData';
import genericAxiosInstance from '../../axios/GenericAxios';
import { useForm } from 'react-hook-form';
interface Props { 
    visible:boolean
    setVisible: (a:boolean) => void
    onChange:() => void,
    data: IGenericData;
    toast: (obj:any) => void
    fetch: () => Promise<void>;
}



const ModalGeneric: React.FC<Props> = ({visible,setVisible,data,toast,fetch} : Props)  => {
    const { reset,trigger,control,register, handleSubmit, watch,setValue, formState: { errors } } = useForm<IGenericData>();
    const { isMobile }                          = useContext(DeviceContext);
    const [buttonClassesSave, setButtonClassesSave]     = useState(['pi', 'pi-save']);//pi pi-save pi-spin
    const createGeneric = async (gd : IGenericData) : Promise<void> => {
        try {
            saving();
            gd.type = 2;
            if (gd.id>0) {
                (gd as IGenericData & UpdateFields).updateFields = "description";
            }
            const userCreated = await genericAxiosInstance.createGeneric(gd);
            saved();
            fetch()
            setVisible(false)
            toast({ severity: 'info',  detail: <div>La Obra <b>{gd.description}</b> fue {gd.id == null ? 'creada' : 'editada'}</div>});
        } catch (e : any) {
            toast({ severity: 'error',  detail: e.message });
            saved();
            console.error(e);
        }
        
    }
    const saving = () => {
        setButtonClassesSave([...buttonClassesSave.filter(className => className !== 'pi-save'), 'pi-spin','pi-spinner']);

    }
    const saved = () => {
        setButtonClassesSave([...buttonClassesSave, 'pi-save']);
        setButtonClassesSave(buttonClassesSave.filter(className => (className !== 'pi-spin' && className !== 'pi-spinner')));
    }

    const onSubmit = (data : IGenericData) => {

        createGeneric(data);
    
    }
    const handleShow = () => {
        if (data.id == undefined) {
            
            reset({description:""})
        } else {
            reset(data)
        }
    }

   


    return (<>
        <Dialog header="Nueva Obra" visible={visible} maximizable onShow={handleShow} style={{ width: (isMobile ? '100%' : "50vw" )}} onHide={() => setVisible(false)}>
            <form onSubmit={handleSubmit(onSubmit)}>
            {/*<Button label="Set" className="w-auto mt-3" onClick={(e) => {e.preventDefault();setValue("asignacion",{ nombre: 'Persona 1', id: 1,estado:1 })}}/>*/}
                <div className="grid formgrid p-fluid mt-2">
                    <div className="field mb-4 col-12">
                        <label htmlFor="username" className="font-medium text-900">Nombre</label>
                        <InputText  id="username" type="text" {...register("description", { required: true })} className={errors.description ? "p-invalid" : ""} />
                        {errors.description && <small className="p-error">Campo requerido</small>}
                    </div>




                    <div className="surface-border border-top-1 opacity-50 mb-3 col-12"></div>

                   
                    <div className="flex flex-wrap justify-content-center gap-3">
                        <Button label="Guardar" className="w-auto mt-3" icon={buttonClassesSave.join(' ')}></Button>
                        <Button type="button" label="Cerrar" severity='secondary' icon="pi pi-times" className="w-auto mt-3" onClick={() => setVisible(false)} />
                    </div>
                </div>
            </form>
        </Dialog>
    </>)
}
export default ModalGeneric;