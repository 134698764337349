import {useContext, useState} from 'react';
import {Button} from 'primereact/button';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import { InputText } from 'primereact/inputtext';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';
import { Link, useNavigate } from 'react-router-dom';
import AuthContext from '../../componentes/authProvider/AuthContext';
import { Message } from 'primereact/message';
import Footer from '../../componentes/main/Footer';

interface Props {
    setIsAuthenticated : (a: boolean) => void
}

function Login({ setIsAuthenticated } : Props) {
  const { token ,setTokenStorage} = useContext(AuthContext);

  const [checked,setChecked] = useState<boolean>(false);
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [session,setSession]    = useState<number>(0);
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      if (username==='' && password==='') {
        setSession(-1);
        return;
      }
      const response = await fetch(process.env.REACT_APP_URL_BACKEND + '/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ login:username,password: password })
      });

      if (response.ok) {
        const { data } = await response.json();
        if (data.token) {
          setTokenStorage(data.token);
          //setToken(data.token);
          navigate('/');
          setSession(1);
        } else {
          setSession(-1);
        }
        
      } else {
        console.error('Error al iniciar sesión');
        
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //  setMessage(event.target.value);
    console.log(event.target.value);
  };
    return (
      <div className="flex align-items-center justify-content-center">
      <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
          <div className="text-center mb-5">
              <img src="/imgs/logo.svg" alt="hyper" height={150} className="mb-3" />
              <div className="text-900 text-3xl font-medium mb-3">Gestor de tareas</div>
              {/*<span className="text-600 font-medium line-height-3">Don't have an account?</span>
              <a className="font-medium no-underline ml-2 text-blue-500 cursor-pointer">Create today!</a>*/}
          </div>
  
          <div>
            <form onSubmit={handleSubmit}>
              <label htmlFor="email" className="block text-900 font-medium mb-2">Login</label>
              <InputText onChange={(e) => setUsername(e.target.value)} id="email" type="text" placeholder="Login" className="w-full mb-3" />
  
              <label htmlFor="password" className="block text-900 font-medium mb-2">Contraseña</label>
              <InputText onChange={(e) => setPassword(e.target.value)} id="password" type="password" placeholder="Contraseña" className="w-full mb-3" />
              {/*<div className="flex align-items-center justify-content-between mb-6">
                  <div className="flex align-items-center">
                      <Checkbox id="rememberme" onChange={(e : CheckboxChangeEvent)=> setChecked(e.checked as boolean)} checked={checked} className="mr-2" />
                      <label htmlFor="rememberme">Remember me</label>
                  </div>
                  <a className="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer">Forgot your password?</a>
            </div>*/}
              {session==-1 && <Message severity="error" className="flex  mb-3 align-items-center justify-content-center" text="Clave o usuario incorrecto" />}
              <Button label="Acceder" icon="pi pi-user" className="w-full mt-4" />
              
            </form>
          </div>
          <Footer/>
      </div>
      
  </div>
    );
}

export default Login;
