
import { Dialog } from 'primereact/dialog';
import {useContext, useState,useEffect, useRef, createRef, RefObject} from 'react';
import { Link } from 'react-router-dom';
import ITask from '../../../../../commons/types/tasks/ITask';
import { fechaScreen, removeSpacesAndHyphens } from '../../../utils/Utils';
import { DeviceContext } from '../../providers/DeviceProvider';
import imgWts from '../../../assets/imgs/wts.png'
import { Button } from 'primereact/button';

interface Props {
    task: ITask;
    visible: boolean;
    setVisible: (a : boolean) => void;
}
interface Props2 extends React.HTMLAttributes<HTMLButtonElement> {
}
const ModalTaskWts : React.FC<Props> = ({task,visible,setVisible} : Props) => {
    const { isMobile }                              = useContext(DeviceContext);
    const handleShowDialog = () => {

    }
    const TemplateWts = ({...attrs} : Props2) => {
        if (isMobile) {
          return (
            <>
              <Button
                {...attrs}
                icon="pi pi-whatsapp"
                label="WhatsApp"
                className="p-button-rounded p-button-success p-button-outlined"
                onClick={() =>
                  window.open(
                    `https://api.whatsapp.com/send?phone=${removeSpacesAndHyphens(task.userAsigned.telefono)}&text=La tarea${(encodeURIComponent('\r\n' + task.description + '\r\nvence el '))} ${fechaScreen(
                      task.dueDate
                    )}`,
                    '_blank'
                  )
                }
              />
            </>
          );
        }
        return (
          <>
            <Button
                {...attrs}
              icon="pi pi-whatsapp"
              label="WhatsApp"
              className="p-button-rounded p-button-success"
              onClick={() =>
                window.open(
                  `https://web.whatsapp.com/send?phone=${removeSpacesAndHyphens(task.userAsigned.telefono)}&text=La tarea${(encodeURIComponent('\r\n' + task.description + '\r\nvence el '))} ${fechaScreen(
                    task.dueDate
                  )}`,
                  '_blank'
                )
              }
            />
          </>
        );
      };
    return (<>
        <Dialog header="Nueva Tarea" visible={visible} onShow={handleShowDialog} maximizable style={{ width: (isMobile ? '100%' : "50vw" )}} onHide={() => setVisible(false)}>
            <form >
                <div className="grid formgrid p-fluid mt-2"></div>
                <div className="field mb-4 col-12 md:col-12 flex justify-center">
                <TemplateWts style={{marginRight:"auto",marginLeft:"auto"}}></TemplateWts>
                </div>
                
            </form>
        </Dialog>
    </>);
}

export default ModalTaskWts;