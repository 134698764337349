import IGenericData from "../../../../commons/types/genericData/IGenericData";
import IUser from "../../../../commons/types/users/IUser";
import getAxiosInstance from "../../utils/AxiosUtil";
import BackendResponse from '../../../../commons/axios/BackendResponse'

class GenericAxios {

    private axiosInstance : any;
    private statusOk : number;
    constructor() {
        this.axiosInstance = getAxiosInstance();
        this.statusOk = 200;
        console.log(this.axiosInstance);
    }

    public async createGeneric(gd : IGenericData) : Promise<IUser> {
        try {
            const result : BackendResponse | any = await this.getAxios().post("/api/generic/save/",gd);
        
            if (result.status != this.statusOk) {
                console.log(result.result)
                throw new Error(result.errorResponse?.message);
            }
            return result.data.data;
        } catch (e : any) {
            throw new Error(e.response.data.errorResponse.message);
        }
        
    }

    public async fetch(typeGeneric : number) : Promise<IGenericData[]> {
        try {
            const result : BackendResponse | any = await getAxiosInstance().post("/api/generic/fetch/" + typeGeneric);

            if (result.status != this.statusOk) {
                console.log(result.result)
                throw new Error(result.errorResponse?.message);
            }
            return result.data.data;
        } catch (e : any) {
            throw new Error(e.response.data.errorResponse.message);
        }
        
    }

    public async delete(id : number) : Promise<void> {
        console.log(id)
        try {
            if (id == null || id<1) {
                throw new Error("Id incorrecto");
            }
            await this.getAxios().delete("/api/generic/" + id);
        
        } catch (e : any) {
            console.error(e)
            throw new Error(e.response.data.errorResponse.message);
        }
    }

    private getAxios() {
        if (!this.axiosInstance) {
            this.axiosInstance = getAxiosInstance();
        }
        return this.axiosInstance;
    }
}

const genericAxiosInstance = new GenericAxios();
export default genericAxiosInstance;