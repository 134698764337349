export const fechaScreen = (date : Date) => {
  if (date && !(date instanceof Date)) {
    date = new Date(date)
  }
    if (date && date instanceof Date) {
      return addCero(date.getDate()) + "/" + addCero(date.getMonth()+1) + "/" + date.getFullYear();
    }
    return "";
  }
  
export const addCero = (str : string | number) => {
    str = (str.toString())
    if (str.length==1) {
      return "0" + str;
    } else if (str.length==0) {
      return "00";
    }
    return str;
  }
/**
 * compara en dos Date, la fecha YYYY-MM-DD sean iguales
 * @param date1 
 * @param date2 
 * @returns 
 */
  export const compareDate = (date1 : Date,date2: Date) => {


    date1.setHours(0, 0, 0, 0);
    date2.setHours(0, 0, 0, 0);

    return (date1.getTime() === date2.getTime());

  }
  /**
   * si date1 es mayor devuelve 1
   * si date 2 es mayor devuelve 2
   * si son iguales devuelve 0
   * @param date1 
   * @param date2 
   * @returns 
   */
  export function compareDates(date1: Date, date2: Date): number {
    // Establecer la hora en 0 para ambas fechas
    date1.setHours(0, 0, 0, 0);
    date2.setHours(0, 0, 0, 0);
  
    if (date1 > date2) {
      return 1;
    } else if (date1 < date2) {
      return 2;
    } else {
      return 0;
    }
  }

  export function removeSpacesAndHyphens(text: string | null | undefined): string {
    // Eliminar espacios y guiones utilizando expresiones regulares
    if (text) {
      const result = text.replace(/[\s-]/g, '');
      return result;
    }
    return "";
  }