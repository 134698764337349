import React from 'react';
import { BreadCrumb } from 'primereact/breadcrumb';

interface Props {
    children?: React.ReactNode;
    titleMain:string;
    titleSecondary:string;
 }
const PageTitle: React.FC<Props> = ({titleMain,titleSecondary,children}) => {
    const items = [{ label: titleMain }, { label: titleSecondary }];
    const home = { icon: 'pi pi-home', url: '/' }

    return (
        <><BreadCrumb model={items} home={home} className="mb-3"/>{children}</>
    )
}

export default PageTitle;