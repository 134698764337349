import {useContext, useState,useEffect, useRef,RefObject,createRef} from 'react';
import { locale, addLocale, updateLocaleOption, updateLocaleOptions, localeOption, localeOptions } from 'primereact/api';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { DeviceContext } from '../../providers/DeviceProvider';
import { InputText } from 'primereact/inputtext';
import { Controller, useForm } from 'react-hook-form';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import IUser from '../../../../../commons/types/users/IUser';
import userAxiosInstance from '../../axios/UserAxios';
interface Props { 
    visible:boolean
    setVisible: (a:boolean) => void
    onChange:() => void,
    data: IUser;
    toast: (obj:any) => void
}

interface LocalIUser extends IUser {
    repass:string
}
const ModalUsersChangePass: React.FC<Props> = ({visible,setVisible,data,toast} : Props)  => {
    const { reset,trigger,control,register, handleSubmit, watch,setValue, formState: { errors } } = useForm<LocalIUser>();
    const { isMobile }                                  = useContext(DeviceContext);
    const [buttonClassesSave, setButtonClassesSave]     = useState(['pi', 'pi-save']);//pi pi-save pi-spin
    const [showPass,setShowPass]                        = useState<boolean>(true);
    const refRePass  : RefObject<HTMLInputElement>        = createRef<HTMLInputElement>();
    const createUser = async (user : IUser) : Promise<void> => {
        try {
            saving();
            await userAxiosInstance.changePassword(user);
            saved();
            setVisible(false)
            toast({ severity: 'info',  detail: <div>Clave modificada al usuario <b>{user.username}</b></div>});
        } catch (e : any) {
            toast({ severity: 'error',  detail: e.message });
            saved();
            console.error(e);
        }
        
    }
    const saving = () => {
        setButtonClassesSave([...buttonClassesSave.filter(className => className !== 'pi-save'), 'pi-spin','pi-spinner']);

    }
    const saved = () => {
        setButtonClassesSave([...buttonClassesSave, 'pi-save']);
        setButtonClassesSave(buttonClassesSave.filter(className => (className !== 'pi-spin' && className !== 'pi-spinner')));
    }

    const onSubmit = (data : IUser) => {

        createUser(data);
    
    }
    const handleShow = () => {
        if (data.id == null || data.id == undefined || data.id < 1) {
            setShowPass(true);
        } else {
            setShowPass(false);
        }
        reset(data)
    }

    useEffect(() => {
        
    },[data])
    const passValidate = (iText :string) => {

      console.log(watch('password') + " - " + refRePass.current?.value);
        if (watch('password') !== refRePass.current?.value) {
          return false;
        }
      
        return true;
        // return iText[0].toUpperCase() === iText[0];
      }

    return (<>
        <Dialog header="Cambiar Contraseña" visible={visible} maximizable onShow={handleShow} style={{ width: (isMobile ? '100%' : "50vw" )}} onHide={() => setVisible(false)}>
            <form autoComplete="new-password"  onSubmit={handleSubmit(onSubmit)}>
            {/*<Button label="Set" className="w-auto mt-3" onClick={(e) => {e.preventDefault();setValue("asignacion",{ nombre: 'Persona 1', id: 1,estado:1 })}}/>*/}
                <div className="grid formgrid p-fluid mt-2">
                    
                    <div className="surface-border border-top-1 opacity-50 mb-3 col-12"></div>
                    <div className="field mb-4 col-12 md:col-6">
                        <label htmlFor="password" className="font-medium text-900" >Contraseña </label>
                        <InputText autoComplete="new-password"  id="password" type="password" {...register("password", { required: true, minLength: 6 })} className={errors.password ? "p-invalid" : ""} />
                        {errors.password?.type === 'required' && <small className="p-error">Campo requerido</small>}
                        {errors.password?.type === 'minLength' && <small className="p-error">Mínimo 6 caracteres</small>}
                    </div>

                    <div className="field mb-4 col-12 md:col-6">
                        <label htmlFor="repass" className="font-medium text-900" >Repetir Contraseña</label>
                        <Controller
                            name="repass"
                            control={control}
                            rules={{ required: true,validate: { passValidate } }}

                            render={({ field, fieldState }) => (
                                <InputText  
                                value={field.value ?? ''}
                                ref={refRePass}  
                                autoComplete="new-password" 
                                name="repass"
                                type="password" 
                                onChange={(e) => field.onChange(e.target.value)}
                                className={errors.repass ? "p-invalid" : ""} />
                    )}
                />
                        
                        {errors.repass?.type === 'required' && <small className="p-error">Campo requerido</small>}
                        {errors.repass?.type === 'passValidate' && <small className="p-error">Las contraseñas no coinciden</small>}
                    </div>
                    <div className="surface-border border-top-1 opacity-50 mb-3 col-12"></div>
                    
                    <div className="surface-border border-top-1 opacity-50 mb-3 col-12"></div>
                    <div className="flex flex-wrap justify-content-center gap-3">
                        <Button label="Guardar" className="w-auto mt-3" icon={buttonClassesSave.join(' ')}></Button>
                        <Button type="button" label="Cerrar" severity='secondary' icon="pi pi-times" className="w-auto mt-3" onClick={() => setVisible(false)} />
                    </div>
                </div>
            </form>
        </Dialog>
    </>)
}
export default ModalUsersChangePass;