import React from 'react';
import { Link } from 'react-router-dom';

interface Props { }


const Footer: React.FC<Props> = props => {
    return <>
    <div className="surface-section px-12 md:px-12 lg:px-12 mt-4">
        <div className="flex align-items-center justify-content-center">
            <div>
                <div className="mt-2 line-height-3">&copy; 2023 Tareas Brickell, Desarrollado por <Link style={{color:"var(--blue-600)"}} to="https://www.intway.com.ar" target='_blank'>Intway Software</Link></div>
            </div>
        </div>
    </div>
    </>
}

export default Footer;