import {useState,useEffect,useRef,createRef, MutableRefObject, RefObject, useContext} from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ITask from '../../../../commons/types/tasks/ITask';
import { fechaScreen } from '../../utils/Utils';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast';
import IUser from '../../../../commons/types/users/IUser';
import PageTitle from '../../componentes/pageTitle/PageTitle';
import ModalUser from '../../componentes/modals/users/ModalContacts';
import { DeviceContext } from '../../componentes/providers/DeviceProvider';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import userAxiosInstance from '../../componentes/axios/UserAxios';
import { Skeleton } from 'primereact/skeleton';
interface Props { }

interface IBtn {
    id: number | null
    btn : RefObject<Menu>
}
const ContactsList: React.FC<Props> = props => {
    const [data,setData]                = useState<IUser[]>([]);
    const [dataClicked,setDataClicked]  = useState<IUser>({} as IUser);
    const [refBtns,setRefBtns]          = useState<IBtn[]>([]);
    const [visible,setVisible]          = useState<boolean>(false);
    const [loading,setLoading]          = useState<boolean>(true);
    const { windowWidth }               = useContext(DeviceContext);
    const toast                         = useRef<Toast>(null);
    const url = window.location.href;
    const roltmp = url.includes("contactos") ? 2 : 1
    const [rol,setRol]                  = useState<number | null>(roltmp);
    const showToast = (obj : any) => {
        toast.current?.show(obj);
    };

    const items = [
        {
            label: 'Options',
            items: [
                {
                    label: 'Editar',
                    icon: 'pi pi-refresh',
                    command: () => {
                        console.log(dataClicked)
                        setVisible(true)
                    }
                },
                {
                    label: 'Eliminar',
                    icon: 'pi pi-times',
                    command: () => {
                        handleClickDelete();
                    }
                }
            ]
        }
    ];

    const fetchUsers = async (irol: number | null) : Promise<void> => {
        if (irol == null || irol == undefined) {
            irol = rol;
        }
        try {
            setLoading(true);
            const response : IUser[] = await userAxiosInstance.fetch(2);
            setLoading(false);
            console.log(response)
            let list2 : IBtn[] = [];
            response.map(e => {
                const tmp : RefObject<Menu> = createRef<Menu>();
                const item : IBtn = {id: e.id, btn: tmp}
                list2.push(item); 
            })
            setRefBtns([...refBtns,...list2]);
            setData(response);
        } catch (e) {
            console.error(e);
        }
        
  }
   
    
      useEffect(() => {
        
        //TODO:

      },[]);

      useEffect(() => {
        if (rol) {
            fetchUsers(rol);
        }
      },[rol]);


    const handleBtnClick = (data : IUser) => {
        setDataClicked(data);
        
    }
    const handleDateFormat = (data : ITask) => {
        return <>{fechaScreen(data.dueDate)}</>;
    }
    const handleActions = (data : IUser) => {
        const tmp : IBtn = refBtns.filter(e => e.id === data.id)[0];
        return <>
                    <Menu model={items} popup ref={tmp.btn} />
                    <Button size="small" icon="pi pi-bars" onClick={(e) => {tmp.btn && tmp.btn.current &&  tmp.btn.current.toggle(e);handleBtnClick(data)}} >
                        </Button>
                </>
    }
    const handleNewUser = () => {
        setVisible(true)
        setDataClicked({} as IUser);
    }
    const doAnything = async () => {
        //NOTHING
    }
    const handleDelete = async () => {
        if (dataClicked.id) {
            await userAxiosInstance.delete(dataClicked.id);
            showToast({ severity: 'info',  detail: <div>El usuario <b>{dataClicked.username}</b> fue eliminado!</div>});
            fetchUsers(rol);
        }
        
    }
    const handleClickDelete = () => {
        confirmDialog({
            message: <div>Desea proceder elimando al usuario <b>{dataClicked.username}</b>?</div>,
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: handleDelete,
            reject: doAnything
        });
    };
    return (<div>
        <ModalUser onChange={function () {}} visible={visible} setVisible={setVisible} data={dataClicked} toast={showToast} fetchUsers={fetchUsers}/>
        <Toast ref={toast} />
        <ConfirmDialog />
        <PageTitle titleMain='Contactos'  titleSecondary='Lista' />
        <Button size="small" label='Nuevo Contacto' icon="pi pi-plus" onClick={(e) => {handleNewUser()}} className="p-button-sm mb-4"></Button>
        <div className="overflow-x-auto" style={{maxWidth:windowWidth-15}}>
        { loading &&  <>
            <Skeleton height="1.3rem" className="mb-2"></Skeleton>
            <Skeleton height="1.3rem" className="mb-2"></Skeleton>
            <Skeleton height="1.3rem" className="mb-2"></Skeleton>
        </>}
        {! loading && <DataTable value={data} showGridlines >
            <Column field="username" header="Nombre"></Column>
            <Column field='telefono' header="Teléfono"></Column>
            <Column body={handleActions} header="" style={{ width: '10px' }}></Column>
        </DataTable>}
        </div>
    </div>)
}
export default ContactsList;